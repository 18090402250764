export const freeTools = [
  'privacy-policy-summarizer',
  'bar-graph-maker',
  'chatbot-name-generator',
  'bar-chart-generator-ai',
  'pie-chart-generator-ai',
  'line-chart-generator-ai',
  'llm-comparison-tool',
  'column-chart-generator-ai',
  'table-generator-ai',
  'meta-information-generator-ai',
  'meta-information-generator-ai-popupsmart',
  'tweet-generator-ai',
  'instagram-post-generator-ai',
  'youtube-post-generator-ai',
  'api-pricing-calculator',
  'create-whatsapp-links',
  'page-speed-insights',
  'image-alt-text-generator-ai',
  'ai-blog-outline-creator',
  'ai-blog-post-ideas-generator',
  'ai-business-name-generator',
  'ai-competitor-analysis-tool',
  'ai-document-summarizer',
  'ai-email-generator-tool',
  'ai-product-description-generator',
  'ai-resume-analyzer',
  'ai-sentence-rewriter-tool',
  'ai-slogan-generator',
  'ai-subject-line-generator',
  'ai-text-humanizer',
  'ai-faq-generator',
  'ai-answers-generator',
  'ai-text-expander',
  'ai-cover-letter-generator',
  'ai-job-description-generator',
  'ai-reference-letter-generator',
  'ai-sentiment-analyzer',
  'ai-dialogue-generator',
  'typing-speed-test',
  'ai-grammar-fixer',
  'ai-acronyms-generator',
  'ai-prompt-generator',
  'ai-tone-checker',
  'ai-bullet-points-to-paragraph-generator',
  'ai-bullet-points-generator',
  'ai-persona-generator',
];

export const safePaths = [
  'static',
  '_next',
  'api',
  'register',
  'login',
  'ph-demo',
  'retool-login',
  'forgot-password',
  'reset-password',
  'accept-invite',
  'aibot-iframe',
  'embed.js',
  'assets',
  'ai-bot',
  'free-tools',
  'preview',
  ...freeTools, // All free tools must be safe paths
];

export const posthogDisabledPages = [
  'static',
  '_next',
  'api',
  'register',
  'login',
  'retool-login',
  'aibot-iframe',
  'embed.js',
  'assets',
  'ai-bot',
  ...freeTools, // Posthog must be disabled for all free tools
];
